import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "./layout/theme/MainThemeMUI5";
import { Provider } from "react-redux";
import store from "./store";
import { SnackbarProvider } from "notistack";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { queriesException } from "./utils/constants";

interface Props {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      // gcTime: 0,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function Providers({ children }: Props) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            const queryKey = query.queryKey[0] as string;
            if (queriesException.includes(queryKey)) {
              return true;
            }
            return false;
          },
        },
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={5}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {children}
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </Provider>
    </PersistQueryClientProvider>
  );
}

export default Providers;
