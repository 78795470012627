import moment from "moment";
import store from "../store";
import appConfig from "@tnlm/config/app.config";

const { userData } = store.getState().user;

const userRoleList = ["Admin", "Manager", "Member"];

export const isMaster = userData?.roleGroup === userRoleList[0];
export const isManager = userData?.roleGroup === userRoleList[1];
export const isMember = userData?.roleGroup === userRoleList[2];
export const isEmployee =
  userData?.username.indexOf("emp_") === 0 ? true : false;

// Flag use for QA test some case
export const isQATest = localStorage["isQaTest"] === "true";

export const isExportUnlimited =
  userData?.setting?.exportTypeLimit === "UNLIMITED";
export const isExportLimited = userData?.setting?.exportTypeLimit === "LIMITED";
export const isExportDisabled = false;
// export const isExportDisabled =
//   userData?.setting?.exportTypeLimit === "DISABLED";

export const isProduction = appConfig.tnlmEnv === "PRODUCTION";
export const isDemo = appConfig.tnlmEnv === "DEMO";
export const isStaging = appConfig.tnlmEnv === "STAGING";
export const isPrototype = isStaging || isDemo;

export const debounceTime = 500;

export const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
export const isAndroid = /(Android)/g.test(navigator.userAgent);

export const notificationModule = [
  { module: "DailyFollowupTask", type: "MY ACTIVITY", typeId: 1 },
  { module: "ProjectPipeline", type: "BCI UPDATE", typeId: 3 },
  { module: "DailySummary", type: "BCI UPDATE", typeId: 3 },
  { module: "KeyAccount", type: "BCI UPDATE", typeId: 3 },
  { module: "ProjectPipelineRealtime", type: "BCI UPDATE", typeId: 3 },
  { module: "SaveSearch", type: "BCI UPDATE", typeId: 3 },
];

export const analytixBaseUrl = {
  staging: "https://dashboard.staging.bci-tnlm.com",
  demo: "https://dashboard.demo.bci-tnlm.com",
  production: "https://dashboard.bci-tnlm.com",
};

export const compactNumberFormatter = Intl.NumberFormat("en", {
  notation: "compact",
});

export const checkIsInactivity = () => {
  const sessionTimeout = localStorage.getItem("sessionTimeout");
  const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const duration = moment.duration(
    moment(currentTime).diff(moment(sessionTimeout))
  );

  return duration.asHours() >= 4;
};

// Chart Constants
export const DEFAULT_COLOR_PALETTE = [
  "#FF9F9F",
  "#BFCDFF",
  "#9AF9F9",
  "#FF4040",
  "#6082FF",
  "#13F1F1",
  "#E8E8E8",
  "#FFDFDF",
  "#DFE6FF",
  "#0CD2D2",
  "#808080",
];

export const BAR_CHART_MAX_VALUE_MIN = 10;
export const BAR_CHART_BAR_HEIGHT = 44;
export const BAR_CHART_BAR_BORDER_GAP = 8;
export const BAR_CHART_BAR_MARGIN_Y = 16;
export const BAR_CHART_BAR_VALUE_GAP = 8;
export const BAR_CHART_BAR_FULL_HEIGHT =
  BAR_CHART_BAR_HEIGHT + BAR_CHART_BAR_BORDER_GAP * 2;
export const BAR_CHART_BAR_VALUE_WIDTH = 192;
export const BAR_CHART_DEFAULT_BAR_LABEL_WIDTH = 256;
export const BAR_CHART_DEFAULT_BAR_LABEL_OFFSET =
  BAR_CHART_BAR_VALUE_WIDTH + BAR_CHART_DEFAULT_BAR_LABEL_WIDTH;
export const BAR_CHART_COMPANY_BAR_LABEL_WIDTH = 320;
export const BAR_CHART_COMPANY_BAR_LABEL_OFFSET =
  BAR_CHART_BAR_VALUE_WIDTH + BAR_CHART_COMPANY_BAR_LABEL_WIDTH;
export const BAR_CHART_TICK_VALUE_SEPARATOR = "-";

export const CHART_TOOLTIP_NONE = () => null;

export const queriesException = ["new-feature-list"];
